/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useRef, useState } from "react";
import logo from "../../assets/images/BipiLogoBlack.svg";
import Swal from "sweetalert2";
import "./style.css";
import AuthContext from "../shared/AuthContext";
import * as authData from "../../data/auth";
import { BASE_URL } from "../../config/config";
import axios from "axios";

export const ModalAuth = () => {
  const [mode, setMode] = useState("Sign In");
  const [isSubmit, setIsSubmit] = useState(false);
  const username = useRef("");
  const email = useRef("");
  const password = useRef("");
  const rememberMe = useRef(null);
  const { login } = useContext(AuthContext);

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const loginSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true)
    
    let payload = {
      email: email.current.value,
      password: password.current.value,
      rememberMe: rememberMe.current.checked
    };

    await login(payload)
      .then(() => {
        setIsSubmit(false)
        window.location.href = "/";
      })
      .catch((error) => {
        setIsSubmit(false)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You may be trying to log in with the wrong username or password",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Try Again",
        })
      }
      );
  };

  const regisSubmit = async (event) => {
    setIsSubmit(true)
    await authData.register({
        email: values.email,
        password: values.password,
        confirmPassword: values.password2,
        name: values.name,
        username: values.username
      })
      .then( async (res) => {
        setIsSubmit(false)
        if (res?.code !== 201) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res?.data?.message,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Try Again",
          })
          return;
        }
        const newUser = {
          email: values.email,
          password: values.password,
          rememberMe: false
        }
        await login(newUser)
        .then((response) => {
          window.location.href = "/";
        }).catch((error) => {
          window.location.href = "/after-register";
        })
      })
      .catch((error) => {
        setIsSubmit(false)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something happening, try again..",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Try Again",
        })
      }
      );
  };

  //Custom hook call
  const validate = (event, name, value) => {
    if (!value) {
      setErrors({
        ...errors,
        [name]: name + " is a required field",
      });
    } else {
      delete errors[name];
    }

    const regexUsername = /^[a-zA-Z0-9_]*$/;
    if (name === "username" && !regexUsername.test(values.username)) {
      setErrors({
        ...errors,
        username: "username cannot contain space and special character!",
      });
    } else {
      delete errors[username];
    }

    if (name === "email" && !new RegExp(/^\S+@\S+\.\S+$/).test(values.email)) {
      setErrors({
        ...errors,
        email: "enter a valid email address!",
      });
    } else {
      delete errors[email];
    }

    if (name === "password2" && value !== values.password) {
      setErrors({
        ...errors,
        password2: "Password does not match!",
      });
    } else {
      delete errors[password];
    }
  };

  const handleChange = (event) => {
    event.persist();

    let name = event.target.name;
    let val = event.target.value;
    validate(event, name, val);
    setValues({
      ...values,
      [name]: val,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(errors).length === 0 && Object.keys(values).length === 5) {
      regisSubmit(e);
    } else {
      if (values.password2 !== values.password) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Password does not match!",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Try Again",
        });
        return;
      }
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "The data you entered is invalid",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Try Again",
      });
    }
  };

  const googleLogin = () => {
    setIsSubmit(true)
    window.open(`${BASE_URL}/auth/google`,'_blank');
  }

  const twitterLogin = async () => {
    setIsSubmit(true);
    window.open(`${BASE_URL}/auth/twitter`,'_blank');
  }

  const facebookLogin = async () => {
    setIsSubmit(true)
    window.open(`${BASE_URL}/auth/facebook`);
  }
  

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const status = params.get('status');
    const token = params.get('token');
    
    if (status && token) {

      localStorage.setItem("tokens", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token?.data?.access_token}`;

      window.history.replaceState({}, document.title, window.location.pathname);
      
      window.location.reload();
    }
  }, [])

  return (
    <div className="modal modal-login fade position-absolute top-50 start-50 translate-middle" id="authModal" tabIndex="-1" aria-labelledby="authModalLabel" aria-hidden="true" modal="show" data-bs-keyboard="false" data-bs-backdrop="static">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content p-2">
          <div className="modal-header">
            <img src={logo} alt="Bipi" width="30px" />
            <div className="m-auto">
              <span className={`me-4 ${mode === "Sign In" && "text-decoration-none text-dark pe-none fw-bold"}`} onClick={() => setMode("Sign In")} 
                role="button">
                Sign in
              </span>
              <span
                className={`${mode === "Sign Up" && "text-decoration-none text-dark pe-none fw-bold"}`}
                onClick={() => {
                  setMode("Sign Up");
                }}
                role="button"
              >
                Sign up
              </span>
            </div>
            <button type="button" className="btn-close ms-0" data-bs-dismiss="modal" onClick={() => setIsSubmit(false)} aria-label="Close"></button>
          </div>

          <div className="modal-body">
            <h6 className="mb-4">{mode === "Sign In" ? "Sign in to your account" : "Create your account"}</h6>
            {mode === "Sign In" ?
              <>
                {/* form login */}
                <form className="row g-3" method="POST" autoComplete="none" onSubmit={loginSubmit}>
                  <div className={`col-sm-12 col-md-6`}>
                    <input type="email" placeholder="Email" className="form-control" ref={email} disabled={isSubmit} required />
                  </div>
                  <div className={`col-sm-12 col-md-6`}>
                    <input type="password" placeholder="*******" className="form-control" ref={password} disabled={isSubmit} required />
                  </div>

                  <div className="d-grid gap-2">
                    <button type="submit" disabled={isSubmit} className="btn btn-primary">
                      Sign In
                    </button>
                  </div>
                  <div className="form-check">
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <input type="checkbox" disabled={isSubmit} className="form-check-input" ref={rememberMe} />
                        <label className="form-check-label" htmlFor="exampleCheck1">
                          Remember me
                        </label>
                      </div>

                      <div className="">
                        <a className={isSubmit ? 'text-secondary pe-none' : 'text-black pe-auto'} href="/forget-password">
                          Forget Password?
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            : 
              <>
                {/* form register */}
                <form className="row g-3" method="POST" autoComplete="none" onSubmit={handleSubmit}>
                  <div className={`col-sm-12 col-md-6`}>
                    <input type="text" autoComplete="none" placeholder="Name" className="form-control" name="name" onChange={handleChange} disabled={isSubmit} required />
                    <div className="text-danger">{errors.name && errors.name}</div>
                  </div>
                  <div className={`col-sm-12 col-md-6`}>
                    <input type="text" autoComplete="none" placeholder="Username" className="form-control" name="username" onKeyUp={handleChange} maxLength={20} required ref={username} disabled={isSubmit} />
                    <div className="text-danger">{errors.username && errors.username}</div>
                  </div>
                  <div className={`col-sm-12`}>
                    <input type="email" placeholder="Email" className="form-control" name="email" onChange={handleChange} onKeyUp={handleChange} disabled={isSubmit} required />
                    <div className="text-danger">{errors.email && errors.email}</div>
                  </div>
                  <div className={`col-sm-12 col-md-6`}>
                    <input
                      type="password"
                      id="password"
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                      placeholder="*******"
                      className="form-control"
                      name="password"
                      onChange={handleChange}
                      required
                      disabled={isSubmit}
                    />
                    <div id="passwordHelpBlock" className="form-text">
                      Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.
                    </div>
                    <div className="text-danger">{errors.password && errors.password}</div>
                  </div>
                  <div className={`col-sm-12 col-md-6`}>
                    <input type="password" placeholder="Confirm Password" className="form-control" name="password2" onChange={handleChange} disabled={isSubmit} required />
                    <div className="text-danger">{errors.password2 && errors.password2}</div>
                  </div>

                  <div className="d-grid gap-2">
                    <button type="submit" disabled={isSubmit} className="btn btn-primary">
                      Sign Up
                    </button>
                  </div>
                </form>
              </>
            }

            {/*<div className="strike">*/}
            {/*  <span>or sign in with me</span>*/}
            {/*</div>*/}
            <div className="row g-2 mt-3">
              <div className="col-sm-12 col-md-4 d-none">
                <button type="button" disabled={isSubmit} className="btn btn-outline-dark w-100" onClick={() => facebookLogin()}>
                  <i className="fa-brands fa-facebook"></i>
                  &ensp; <span>Sign with Facebook</span>
                </button>
              </div>
              {/*<div className="col-sm-12">*/}
              {/*  <button type="button" disabled={isSubmit} className="btn btn-outline-dark w-100" onClick={() => googleLogin()}>*/}
              {/*    <i className="fa-brands fa-google"></i>*/}
              {/*    &ensp; <span>Sign with Google</span>*/}
              {/*  </button>*/}
              {/*</div>*/}
              <div className="col-sm-12 col-md-4 d-none">
                <button className="btn btn-outline-dark w-100" disabled={isSubmit} onClick={() => twitterLogin()}>
                  <i className="fa-brands fa-twitter"></i>
                  &ensp; <span>Sign with Twitter</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
