import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, {keyframes } from 'styled-components'

import AuthContext from '../shared/AuthContext.js';

const slideInAnimation = keyframes`
  from {
    transform: translateY(100%);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Container = styled.div`
  width: 100%;
  min-height: 50vh;
  
  position: fixed;
  bottom: 0;
  z-index: 1000;

  border-radius: 14px 14px 0px 0px;
  background: #212529;
  color: white;

  display: flex;
  flex-direction: column;
  padding: 1rem;

  animation: ${slideInAnimation} 0.3s ease-out;

  h4 {
    text-align: center;
  }
`;

const MenuItem = styled.span`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  a {
    color: white;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  width: 80%;
  max-width: 400px;

  h2 {
    margin-bottom: 1rem;
  }

  button {
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .confirm {
    background: red;
    color: white;
  }

  .cancel {
    background: gray;
    color: white;
  }
`;

export function ProfileMenu(props) {
  const { logout } = useContext(AuthContext)
  const { parentRef } = props;
  const [showModal, setShowModal] = useState(false);

  const handleDeleteAccount = () => {
    setShowModal(false);
    logout(); // Panggil fungsi penghapusan akun
  };
  
  const Menus = [
    {
      name: 'My Journey',
      href: '/user-posts',
      order: 1
    },
    {
      name: 'Insight',
      href: '/statistic',
      order: 2
    },
    {
      name: 'Settings',
      href: '/settings',
      order: 3
    },
    {
      name: 'Activity Logs',
      href: '/activity/logs/interaction/liked',
      order: 4
    },
    {
      name: 'Delete Account',
      href: '',
      order: 5
    },
    {
      name: 'Sign Out',
      href: '',
      order: 6
    }
  ]

  return (
      <>
        <Container ref={parentRef}>
          <h4>Menu</h4>
          {Menus.map((menu, index) => (
              <MenuItem key={index}>
                {menu.name === 'Delete Account' ? (
                    <span onClick={() => setShowModal(true)}>{menu.name}</span>
                ) : menu.href !== '' ? (
                    <Link to={menu.href}>{menu.name}</Link>
                ) : (
                    <span onClick={logout}>{menu.name}</span>
                )}
              </MenuItem>
          ))}
        </Container>

        {showModal && (
            <ModalOverlay>
              <ModalContent>
                <h2>Delete Account</h2>
                <p>Are you sure you want to delete your account? This action cannot be undone.</p>
                <button className="confirm" onClick={handleDeleteAccount}>Yes, Delete</button>
                <button className="cancel" onClick={() => setShowModal(false)}>Cancel</button>
              </ModalContent>
            </ModalOverlay>
        )}
      </>
  );
}